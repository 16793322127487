.header {
  grid-area: header;
  display: flex;
  align-items: baseline;
  justify-content: center;

  @media (max-width: 599px) {
    margin-bottom: 1em;
    padding: 1em 16px 1em 16px;
    border-width: 4px;
  }
  @media (min-width: 600px) {
    margin-bottom: 1em;
    padding: 2em 16px 2em 16px;
    border-width: 8px;
  }
}

.header__display {
  color: var(--color-brand-4);
  text-align: center;
}