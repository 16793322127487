.task {
  margin-right: 16px;
  margin-left: 16px;
  padding: 1.25em 16px;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid var(--color-brand-4);

  &:nth-of-type(odd) {
    background-color: var(--color-brand-2);
  }

  &:first-of-type {
    border-top: 1px solid var(--color-brand-4);
  }
}

.task__checkbox {
  width: 28px;
  height: 28px;
  display: block;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  clear: both;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .task__checkbox__custom {
      background-color: var(--color-white);
      border-color: var(--color-white);
    
      &:after {
        transform: rotate(45deg) scale(1);
        left: 8px;
        top: 1px;
        width: 9px;
        height: 18px;
        border: solid var(--color-brand-3);
        border-width: 0 3px 3px 0;
        background-color: transparent;
        border-radius: 0;
        opacity: 1;
      }
    }
  
    &:hover,
    &:focus {
      &~ .task__checkbox__custom {
        border-color: var(--color-brand-3);
      }
    }
  }
}

.task__checkbox__custom {
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
  background-color: transparent;
  border: 2px solid var(--color-brand-5);
  border-radius: 4px;
  transition: all var(--global-transition);

  &::after {
    position: absolute;
    content: "";
    border-radius: 4px;
    border-width: 0 3px 3px 0;
    transform: rotate(0deg) scale(0);
    transition: all var(--global-transition);
  }
}

.task__copy {
  margin-left: 16px;
  margin-right: 16px;
  flex-grow: 1;
  
  [data-state-completed="true"] & {
    opacity: .3;
  }  
}

.task__remove-task {
  margin-left: auto;
  padding: 1px 4px;
  flex-shrink: 0;
  border: 2px solid var(--color-warning);
  border-radius: 4px;
  background-color: var(--color-warning);
  color: var(--color-white);

  &:hover,
  &focus {
    border-color: var(--color-brand-4);
  }
}